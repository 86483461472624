<template>
  <div id="send-group-email">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>
      <v-card-text>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="submitForm"
        >
          <v-container>
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12" align="center">
                <h1 class="text-primary">Email Credits : {{ EmailCredits }}</h1>
              </v-col>
              <v-col cols="12" md="12">
                <p class="notes-text1">
                  <span class="text-danger">*</span> indicates required field
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h4 class="text-primary">
                    <span class="text-danger">*</span> Group
                  </h4>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="GroupCodeOptions"
                  :rules="GroupCodeRules"
                  :loading="GroupCodeOptionsLoading"
                  v-model="GroupCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h4 class="text-primary">
                    <span class="text-danger">*</span> Reply To Email ID
                  </h4>
                </label>
                <v-text-field
                  v-model="ReplyToEmailId"
                  :rules="ReplyToEmailIdRules"
                  placeholder="Reply to email id"
                  hint="Enter the Email"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <label>
                  <h4 class="text-primary">
                    <span class="text-danger">*</span> Subject
                  </h4>
                </label>
                <v-text-field
                  v-model="Subject"
                  :rules="SubjectRules"
                  placeholder="Subject"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" v-if="CcMailFlag">
                <label>
                  <h4 class="text-primary">
                    CC Mails
                    <small>(optional)</small>
                  </h4>
                </label>
                <v-text-field
                  v-model="CCMails"
                  :rules="CCMailsRules"
                  placeholder="(ex: abc@mail.com,xyz@mail.com,...,etc)"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="12">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Email Content
                </h4>
                <p class="notes-text1">
                  Kindly note, the following variables can be used in email:
                  <span>
                    <ul class="notes-text1">
                      <li>Name: <b> #name# </b></li>
                    </ul>
                  </span>
                </p>
                <ckeditor v-model="Content" :config="editorConfig"></ckeditor>
                <!-- <tiptap-vuetify
                  v-model="Content"
                  :extensions="extensions"
                  :toolbar-attributes="{ color: 'blue' }"
                /> -->
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  elevation="30"
                  shaped
                  tile
                  small
                  color="#1db954"
                  class="font-size-h6 px-10 mr-3 white--text"
                  >Send</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  mixins: [common],
  components: { TiptapVuetify },
  props: {},
  data() {
    return {
      valid1: true,
      ProgressLoadingFlag: false,
      LoadingFlag: false,
      SubmitFlag: false,

      NotesFlag: false,

      ReplyToEmailId: "",
      ReplyToEmailIdRules: [
        (v) => !!v || "Reply to e-mail id is required",
        (v) => /.+@.+\..+/.test(v) || "Reply to e-mail id must be valid",
      ],

      Subject: "",
      SubjectRules: [(v) => !!v || "Subject is required"],

      ContentRules: [(v) => !!v || "Content is required"],
      Content: "",
      editorConfig: {
        toolbar: [
          { name: "styles", items: ["Format", "Styles", "Font", "FontSize"] },
          {
            name: "basicstyles",
            groups: ["basicstyles", "cleanup"],
            items: [
              "Bold",
              "Italic",
              "Underline",
              "Strike",
              "Subscript",
              "Superscript",
              "-",
              "RemoveFormat",
            ],
          },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"],
            items: [
              "NumberedList",
              "BulletedList",
              "-",
              "Outdent",
              "Indent",
              "-",
              "Blockquote",
              "CreateDiv",
              "-",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
              "-",
              "BidiLtr",
              "BidiRtl",
              "Language",
            ],
          },
          { name: "links", items: ["Link", "Unlink", "Anchor"] },
          {
            name: "insert",
            items: [
              "Image",
              "Flash",
              "Table",
              "HorizontalRule",
              "Smiley",
              "SpecialChar",
              "PageBreak",
              "Iframe",
            ],
          },
          {
            name: "clipboard",
            groups: ["clipboard", "undo"],
            items: [
              "Cut",
              "Copy",
              "Paste",
              "PasteText",
              "PasteFromWord",
              "-",
              "Undo",
              "Redo",
            ],
          },
          {
            name: "editing",
            groups: ["find", "selection", "spellchecker"],
            items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
          },
          {
            name: "forms",
            items: [
              "Form",
              "Checkbox",
              "Radio",
              "TextField",
              "Textarea",
              "Select",
              "Button",
              "ImageButton",
              "HiddenField",
            ],
          },
          { name: "colors", items: ["TextColor", "BGColor"] },
          { name: "tools", items: ["Maximize", "ShowBlocks"] },
          {
            name: "document",
            groups: ["mode", "document", "doctools"],
            items: [
              "Source",
              "-",
              "Save",
              "NewPage",
              "Preview",
              "Print",
              "-",
              "Templates",
            ],
          },
        ],
      },

      CCMails: "",
      CCMailsRules: [],
      CcMailFlag: false,

      EmailCredits: 0,

      GroupCodeRules: [(v) => !!v || "Group is required"],
      GroupCode: "",
      GroupCodeOptions: [],
      GroupCodeOptionsLoading: false,

      ZoneCode: "",
      ZoneCodeRules: [],
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCode: "",
      LomCodeRules: [],
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      YearHalf: "",
      YearHalfRules: [],
      YearHalfOptions: [
        { value: "", text: "Select Half" },
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      OrganizationType: "",
      OrganizationTypeRules: [(v) => !!v || "Governing Board Type is required"],
      OrganizationTypeOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "National" },
        { value: 2, text: "Zone" },
        { value: 3, text: "LO" },
      ],
      OrganizationTypeOptionsLoading: false,

      DesignationAreaCode: "",
      DesignationAreaCodeOptions: [],
      DesignationAreaCodeRules: [],
      DesignationAreaCodeOptionsLoading: false,

      DesignationZoneCode: "",
      DesignationZoneCodeRules: [],
      DesignationZoneCodeOptions: [],
      DesignationZoneCodeOptionsLoading: false,

      DesignationLomCode: "",
      DesignationLomCodeRules: [],
      DesignationLomCodeOptions: [],
      DesignationLomCodeOptionsLoading: false,

      DesignationCode: [],
      DesignationCodeRules: [],
      DesignationCodeOptions: [],
      DesignationCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",

      extensions: [
        History,
        Blockquote,
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        var EmailCredits = parseInt(tr.EmailCredits);
        console.log({ EmailCredits });
        this.EmailCredits = EmailCredits;

        this.getGroupCodeOptions();
      }
    },
    GroupCodeOptions: function () {
      console.log("watch GroupCodeOptions");
      this.GroupCodeOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "communication",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getGroupCodeOptions() {
      console.log("getGroupCodeOptions called");
      this.GroupCodeOptionsLoading = true;
      var selectbox1_source = "GroupCode";
      var selectbox1_destination = "GroupCodeOptions";
      var selectbox1_url = "api/group/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    resetForm() {
      this.$refs.form1.reset();
      this.GroupCode = "";
      this.Content = "";
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var upload = {
          UserInterface: 1,
          Group: this.GroupCode,
          ReplyTo: this.ReplyToEmailId,
          Subject: this.Subject,
          Content: this.Content,
          CCMails: this.CCMails,
        };
        console.log({ upload });

        this.SubmitFlag = true;
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/communication/send-group-email";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var EmailCredits = records.EmailCredits;
            console.log({ EmailCredits });

            thisIns.EmailCredits = EmailCredits;

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.resetForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#send-group-email {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
}
</style>